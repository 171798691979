import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppAmplifyTokenService } from '@app/app-amplify-token.service';
import { UntilDestroy, UntilDestroyed } from './shared/utils/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { CallPopupService, CustomParameters } from './calls/services/call-popup/call-popup.service';
import { CallsService } from './calls/services/calls/calls.service';
import { TrackerEventsService } from './shared/services/tracker-events.service';
import { HotLeadPollingService } from './hot-lead-polling/service/hot-lead-polling.service';
import { AuthorizeWithRingcentralService } from './shared/services/authorize-with-ringcentral.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  incomingCallCustomParameters$: BehaviorSubject<CustomParameters | null>;

  @ViewChild('authorizeRcTemplate') authorizeRcTemplate: TemplateRef<unknown>;

  constructor(
    private authorizeWithRingcentralService: AuthorizeWithRingcentralService,
    private appAmplifyTokenService: AppAmplifyTokenService,
    private trackerEventsService: TrackerEventsService,
    private callPopupService: CallPopupService,
    private callsService: CallsService,
    protected pollerService: HotLeadPollingService,
  ) {}

  ngOnInit() {
    this.appAmplifyTokenService.startTokenPolling().pipe(UntilDestroyed(this)).subscribe();

    this.incomingCallCustomParameters$ = this.callPopupService.incomingCallCustomParameters$;
    this.callsService.initCalls();
    this.pollerService.initPoller();
    this.trackerEventsService.sendLoginEvent();
  }

  ngAfterViewInit() {
    this.authorizeWithRingcentralService.init(this.authorizeRcTemplate);
  }

  auth() {
    this.authorizeWithRingcentralService.auth();
  }
}
