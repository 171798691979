import { Injectable } from '@angular/core';
import {
  ArchiveOptionGQL,
  ArchiveOptionMutationVariables,
  BookingRevenueProductNode,
  BookingRevenueProductsGQL,
  CopyOptionGQL,
  CopyOptionMutationVariables,
  CreateFlightRequestStatusV2GQL,
  CreateFlightRequestStatusV2MutationVariables,
  CreateFlightRequestTripV2GQL,
  CreateFlightRequestTripV2MutationVariables,
  CreateOrderFromFlightRequestGQL,
  CreateOrderFromFlightRequestMutationVariables,
  CreatePriceToBeatGQL,
  CreatePriceToBeatMutationVariables,
  DeleteFlightRequestTripV2GQL,
  DeletePriceToBeatGQL,
  DeletePriceToBeatMutationVariables,
  FeedbackReasonNode,
  FlightRequestNode,
  GetFeedbackReasonsGQL,
  GetFlightRequestPageV2GQL,
  ReorderPublishedOptionsGQL,
  ReorderPublishedOptionsMutationVariables,
  SaveFlightRequestV2GQL,
  SaveFlightRequestV2MutationVariables,
  SaveSearchTaskCommentGQL,
  SaveSearchTaskCommentMutationVariables,
  SearchTaskCreateGQL,
  SearchTaskCreateMutationVariables,
  SearchTaskStatusChangeGQL,
  SearchTaskStatusChangeMutationVariables,
  SetSearchTaskFeedbackGQL,
  SetSearchTaskFeedbackMutationVariables,
  SetSearcherToSearchTaskGQL,
  SetSearcherToSearchTaskMutationVariables,
  UpdateFlightRequestOrderGQL,
  UpdateFlightRequestOrderMutationVariables,
  UpdateFlightRequestTripV2GQL,
  UpdateFlightRequestTripV2MutationVariables,
  UpdatePriceToBeatGQL,
  UpdatePriceToBeatMutationVariables,
  GetArchivedOptionsV2GQL,
  OrderNode,
  UpdateFlightSegmentFlightRequestGQL,
  UpdateFlightSegmentFlightRequestMutationVariables,
  GetFlightRequestSearchEngineIdGQL,
  GetFlightRequestSearchEngineIdQueryVariables,
  SendQuoteGQL,
  SendQuoteMutationVariables,
  GetClientGmailThreadsGQL,
  GetClientGmailThreadsQueryVariables,
  QuotePreviewGQL,
  QuotePreviewQueryVariables,
  NewChangeRequestOptionFrGQL,
  NewChangeRequestOptionFrMutationVariables,
  SendSmsGQL,
  SendSmsMutationVariables,
  SaveFrBookingMileGQL,
  SaveFrBookingHotelGQL,
  SaveFrBookingRevenueV2MutationVariables,
  SaveFrBookingMileMutationVariables,
  SaveFrBookingHotelMutationVariables,
  SaveFrBookingRevenueV2GQL,
} from '@generated/graphql';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { mapEntityToDropdownItem } from '@app/shared/utils/dropdown-utils';
import {
  hideFormModalIfNoErrors,
  setFormLoadingState,
} from '@app/shared/components/radar-form-abstract';
import { GraphqlID, RefetchQueries } from '@app/shared/types';

@Injectable({
  providedIn: 'root',
})
export class FlightRequestPageV2Service {
  id: GraphqlID | null;

  protected flightRequest$$ = new BehaviorSubject<FlightRequestNode | null>(null);
  protected archivedOptions$$ = new BehaviorSubject<OrderNode[] | null>(null);

  constructor(
    private flightRequestGQL: GetFlightRequestPageV2GQL,

    private saveFlightRequestGQL: SaveFlightRequestV2GQL,
    private createFlightRequestStatusGQL: CreateFlightRequestStatusV2GQL,

    private createPriceToBeatGQL: CreatePriceToBeatGQL,
    private updatePriceToBeatGQL: UpdatePriceToBeatGQL,
    private deletePriceToBeatGQL: DeletePriceToBeatGQL,

    private createOrderGQL: CreateOrderFromFlightRequestGQL,
    private updateOrderGQL: UpdateFlightRequestOrderGQL,
    private archiveOptionGQL: ArchiveOptionGQL,
    private copyOptionGQL: CopyOptionGQL,

    private bookingRevenueProductsGQL: BookingRevenueProductsGQL,

    private reorderPublishedOptionsGQL: ReorderPublishedOptionsGQL,

    private createFlightRequestTripGQL: CreateFlightRequestTripV2GQL,
    private updateFlightRequestTripGQL: UpdateFlightRequestTripV2GQL,
    private deleteFlightRequestTripGQL: DeleteFlightRequestTripV2GQL,

    private searchTaskCreateGQL: SearchTaskCreateGQL,
    private saveSearchTaskCommentGQL: SaveSearchTaskCommentGQL,
    private searchTaskStatusChangeGQL: SearchTaskStatusChangeGQL,
    private setSearcherToSearchTaskGQL: SetSearcherToSearchTaskGQL,
    private getFeedbackReasonsGQL: GetFeedbackReasonsGQL,
    private setSearchTaskFeedbackGQL: SetSearchTaskFeedbackGQL,
    private getArchivedOptionsGQL: GetArchivedOptionsV2GQL,

    private updateFlightSegmentFlightRequestGQL: UpdateFlightSegmentFlightRequestGQL,
    private getFlightRequestSearchEngineIdGQL: GetFlightRequestSearchEngineIdGQL,

    private sendSmsGQL: SendSmsGQL,
    private sendQuoteGQL: SendQuoteGQL,
    private quotePreviewGQL: QuotePreviewGQL,
    private getClientGmailThreadsGQL: GetClientGmailThreadsGQL,

    private newChangeRequestOptionFrGQL: NewChangeRequestOptionFrGQL,

    private saveFRBookingRevenueV2GQL: SaveFrBookingRevenueV2GQL,
    private saveFrBookingMileGQL: SaveFrBookingMileGQL,
    private saveFrBookingHotelGQL: SaveFrBookingHotelGQL
  ) {}

  get flightRequest$() {
    return this.flightRequest$$.asObservable();
  }

  get archivedOptions$() {
    return this.archivedOptions$$.asObservable();
  }

  getFlightRequest(id: GraphqlID) {
    this.id = id;

    this.flightRequestGQL
      .fetch({ id })
      .subscribe(({ data }) => this.flightRequest$$.next(data.flightRequest as FlightRequestNode));
  }

  setFlightRequest(flightRequest: FlightRequestNode | null) {
    if (flightRequest) {
      this.flightRequest$$.next(flightRequest);
    } else if (this.id) {
      this.getFlightRequest(this.id);
    }
  }

  resetFlightRequest() {
    this.id = null;
    this.flightRequest$$.next(null);
  }

  getArchivedOptions(id: GraphqlID) {
    this.archivedOptions$$.next(null);

    return this.getArchivedOptionsGQL
      .fetch({ id })
      .subscribe(({ data }) =>
        this.archivedOptions$$.next(
          data.flightRequest?.archivedOptions?.edges.map((edge) => edge.node as OrderNode) || []
        )
      );
  }

  saveFlightRequest(
    params: SaveFlightRequestV2MutationVariables,
    refetchQueries: RefetchQueries = []
  ) {
    return this.saveFlightRequestGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.saveFlightRequest?.result as FlightRequestNode)
        )
      );
  }

  createPriceToBeat(params: CreatePriceToBeatMutationVariables) {
    return this.createPriceToBeatGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.createPriceToBeat?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  updatePriceToBeat(params: UpdatePriceToBeatMutationVariables) {
    return this.updatePriceToBeatGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.updatePriceToBeat?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  deletePriceToBeat(params: DeletePriceToBeatMutationVariables) {
    return this.deletePriceToBeatGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.deletePriceToBeat?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  createOrderFromFlightRequest(
    params: CreateOrderFromFlightRequestMutationVariables,
    refetchQueries: RefetchQueries = []
  ) {
    return this.createOrderGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.createOrder?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  updateOrder(params: UpdateFlightRequestOrderMutationVariables, refetchQueries?: RefetchQueries) {
    return this.updateOrderGQL.mutate(params, { refetchQueries }).pipe(
      hideFormModalIfNoErrors(),
      setFormLoadingState(false),
      tap(({ data }) =>
        this.setFlightRequest(data?.updateOrder?.result?.flightRequest as FlightRequestNode)
      )
    );
  }

  setFlightRequestStatus(
    data: CreateFlightRequestStatusV2MutationVariables,
    refetchQueries: RefetchQueries = []
  ) {
    return this.createFlightRequestStatusGQL
      .mutate(data, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(
            data?.createFlightRequestStatus?.result?.flightRequest as FlightRequestNode
          )
        )
      );
  }

  archiveOption(data: ArchiveOptionMutationVariables, refetchQueries: RefetchQueries = []) {
    return this.archiveOptionGQL
      .mutate(data, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.archiveOrder?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  unarchiveOption(
    params: UpdateFlightRequestOrderMutationVariables,
    refetchQueries?: RefetchQueries
  ) {
    return this.updateOrderGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.updateOrder?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  getBookingRevenueProducts(): Observable<DropdownItem[]> {
    return this.bookingRevenueProductsGQL.fetch({}, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => {
        return data.bookingRevenueProducts?.edges?.map(({ node }) => {
          return mapEntityToDropdownItem(node as BookingRevenueProductNode);
        }) as DropdownItem[];
      })
    );
  }

  copyOption(params: CopyOptionMutationVariables, refetchQueries: RefetchQueries = []) {
    return this.copyOptionGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.copyOption?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  reorderPublishedOptions(
    params: ReorderPublishedOptionsMutationVariables,
    refetchQueries: RefetchQueries = []
  ) {
    return this.reorderPublishedOptionsGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.reorderPublishedOptions?.result as FlightRequestNode)
        )
      );
  }

  createFlight(
    data: CreateFlightRequestTripV2MutationVariables,
    refetchQueries: RefetchQueries = []
  ) {
    return this.createFlightRequestTripGQL
      .mutate(data, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(
            data?.createFlightRequestTrip?.result?.flightRequest as FlightRequestNode
          )
        )
      );
  }

  updateFlight(data: UpdateFlightRequestTripV2MutationVariables, refetchQueries?: RefetchQueries) {
    return this.updateFlightRequestTripGQL
      .mutate(data, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(
            data?.updateFlightRequestTrip?.result?.flightRequest as FlightRequestNode
          )
        )
      );
  }

  deleteFlight(id: GraphqlID, refetchQueries?: RefetchQueries) {
    return this.deleteFlightRequestTripGQL
      .mutate({ id }, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.deleteFlightRequestTrip?.flightRequest as FlightRequestNode)
        )
      );
  }

  createSearchTask(params: SearchTaskCreateMutationVariables, refetchQueries?: RefetchQueries) {
    return this.searchTaskCreateGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.createSearchTask?.flightRequest as FlightRequestNode)
        )
      );
  }

  saveSearchTaskComment(params: SaveSearchTaskCommentMutationVariables) {
    return this.saveSearchTaskCommentGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.saveSearchTaskComment?.result as FlightRequestNode)
        )
      );
  }

  changeSearchTaskStatus(
    params: SearchTaskStatusChangeMutationVariables,
    refetchQueries?: RefetchQueries
  ) {
    return this.searchTaskStatusChangeGQL
      .mutate(params, { refetchQueries })
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.changeSearchTaskStatus?.flightRequest as FlightRequestNode)
        )
      );
  }

  assignSearcherToSearchTask(params: SetSearcherToSearchTaskMutationVariables) {
    return this.setSearcherToSearchTaskGQL
      .mutate(params)
      .subscribe(({ data }) =>
        this.setFlightRequest(data?.assignSearcherToSearchTask?.flightRequest as FlightRequestNode)
      );
  }

  getFeedbackReasons() {
    return this.getFeedbackReasonsGQL
      .fetch({}, { fetchPolicy: 'cache-first' })
      .pipe(
        map(
          ({ data }) => data.feedbackReasons?.edges.map(({ node }) => node) as FeedbackReasonNode[]
        )
      );
  }

  saveSearchTaskFeedback(params: SetSearchTaskFeedbackMutationVariables) {
    return this.setSearchTaskFeedbackGQL
      .mutate(params)
      .pipe(setFormLoadingState(false))
      .subscribe(({ data }) =>
        this.setFlightRequest(data?.saveSearchTaskFeedback?.result as FlightRequestNode)
      );
  }

  updateFlightSegmentFlightRequest(params: UpdateFlightSegmentFlightRequestMutationVariables) {
    return this.updateFlightSegmentFlightRequestGQL
      .mutate(params)
      .subscribe(({ data }) =>
        this.setFlightRequest(
          data?.updateFlightSegment?.result?.booking?.order?.flightRequest as FlightRequestNode
        )
      );
  }

  getFlightRequestSearchEngineId(params: GetFlightRequestSearchEngineIdQueryVariables) {
    return this.getFlightRequestSearchEngineIdGQL
      .fetch(params)
      .pipe(map(({ data }) => data.flightRequest?.searchEngineId as number));
  }

  sendQuote(params: SendQuoteMutationVariables) {
    return this.sendQuoteGQL
      .mutate(params)
      .pipe(tap(({ data }) => this.setFlightRequest(data?.sendQuote?.result as FlightRequestNode)));
  }

  getClientGmailThreads(params: GetClientGmailThreadsQueryVariables) {
    return this.getClientGmailThreadsGQL
      .fetch(params)
      .pipe(map(({ data }) => data.clientGmailThreads || []));
  }

  quotePreview(params: QuotePreviewQueryVariables) {
    return this.quotePreviewGQL.fetch(params).pipe(map(({ data }) => data.quotePreview || ''));
  }

  newChangeRequestOption(params: NewChangeRequestOptionFrMutationVariables) {
    return this.newChangeRequestOptionFrGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(
            data?.newChangeRequestOption?.result?.flightRequest as FlightRequestNode
          )
        )
      );
  }

  sendSms(params: SendSmsMutationVariables) {
    return this.sendSmsGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.sendSms?.result?.flightRequest as FlightRequestNode)
        )
      );
  }

  saveFRBookingRevenueV2(params: SaveFrBookingRevenueV2MutationVariables) {
    return this.saveFRBookingRevenueV2GQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(
            data?.saveBookingRevenueV2?.result?.flightRequest as FlightRequestNode
          )
        )
      );
  }

  saveFrBookingMile(params: SaveFrBookingMileMutationVariables) {
    return this.saveFrBookingMileGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(
            data?.saveOrderBookingMile?.result?.flightRequest as FlightRequestNode
          )
        )
      );
  }

  saveFrBookingHotel(params: SaveFrBookingHotelMutationVariables) {
    return this.saveFrBookingHotelGQL
      .mutate(params)
      .pipe(
        tap(({ data }) =>
          this.setFlightRequest(data?.saveBookingHotel?.result?.flightRequest as FlightRequestNode)
        )
      );
  }
}
