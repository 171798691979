export const environment = {
  GRAPHQL_URL: 'https://b023g38i08.execute-api.eu-central-1.amazonaws.com/develop/graphql',
  AWS_PROJECT_REGION: 'eu-central-1',
  AWS_COGNITO_REGION: 'eu-central-1',
  AWS_USER_POOLS_ID: 'eu-central-1_f0qK2AMQJ',
  AWS_USER_POOLS_WEB_CLIENT_ID: '6hb1ho1ljjqtr7dnen8rvit9vk',
  OAUTH_DOMAIN: 'develop-radar.auth.eu-central-1.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://develop.voyagu.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://develop.voyagu.com/login',
  PORTAL_URL: 'https://develop-q.bcconsolidator.com',
  PORTAL_BUILD_QUERY_KEY: '6a004d54ad43#14',
  TWILIO_SID: 'AP96e1e5e1cccae1b119285275be056789',
  SEARCH_API: 'https://rmrjbqbiynmxhomgbh6zpdmvju0bfcpn.lambda-url.eu-central-1.on.aws',
  COMMIT_ID: 'f6ccb33cba76feb8e6a14f2606abf4b2dec81936',
  TRACKING_URL: 'https://develop-tr.businessclassconsolidator.com',
  RINGCENTRAL_CLIENT_ID: '3BgVD4M3lwRdOOCb0Cxv5x',
  RINGCENTRAL_CLIENT_SECRET: 'XrqO0Lav78KamiNZXZEOtv3VOxEygiK1ndVQWp2qzmgs',
  RINGCENTRAL_SERVER: 'https://platform.ringcentral.com',
  RINGCENTRAL_REDIRECT_URI: 'https://b023g38i08.execute-api.eu-central-1.amazonaws.com/develop/ring_central_token',
  production: false,
  
};
