import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UserNode } from '@app/generated/graphql';
import { groupBy } from 'lodash';

@Component({
  selector: 'user-assign-button',
  templateUrl: './user-assign-button.component.html',
  styleUrls: ['./user-assign-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAssignButtonComponent implements OnChanges {
  @Input() users: SelectableUser[];
  @Input() currentUser?: UserNode;
  @Input() searchPlaceholder = "Agent's name";
  @Input() showFullname: boolean;
  @Input() readonly: boolean;

  @Output() userSelected: EventEmitter<SelectableUser> = new EventEmitter<SelectableUser>();

  @HostListener('document:keydown.escape', ['$event']) onEscape(): void {
    this.closeAssignModal();
  }

  showAssignModal = false;
  searchFilter = '';
  groupedUsers: {
    name: string;
    value: SelectableUser[];
  }[] = [];

  ngOnChanges(change: SimpleChanges) {
    if (change.users) {
      if (this.users.some((user) => user.groupBy)) {
        const userDictionary = groupBy(this.users, (user) => user.groupBy);
        this.groupedUsers = Object.keys(userDictionary).map((key) => ({
          name: key,
          value: userDictionary[key],
        }));
      } else {
        this.groupedUsers = [
          {
            name: '',
            value: this.users,
          },
        ];
      }
    }
  }

  onUserSelected(selectedUser: SelectableUser) {
    this.showAssignModal = false;
    this.userSelected.next(selectedUser);
  }

  closeAssignModal() {
    this.searchFilter = '';
    this.showAssignModal = false;
  }
}

export type SelectableUser = { label?: string; value?: string; picture?: string; groupBy?: string };
