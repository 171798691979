import { Injectable, Renderer2, RendererFactory2, TemplateRef } from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { RdrAuthService } from '@app/auth/services/rdr-auth.service';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { filter, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GraphqlID } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeWithRingcentralService {
  userId: GraphqlID;

  renderer: Renderer2;

  constructor(
    private cognitoAuthService: CognitoAuthService,
    private rdrAuthService: RdrAuthService,
    private modalV2Service: ModalV2Service,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init(authorizeRcTemplate: TemplateRef<unknown>) {
    this.cognitoAuthService
      .hasCognitoSession()
      .pipe(
        filter((val) => Boolean(val)),
        take(1),
        switchMap(() => this.rdrAuthService.getUser()),
      )
      .subscribe((user) => {
        this.userId = user?.id || '';

        const needAuthorizeRc =
          this.cognitoAuthService.isSales && !this.cognitoAuthService.user.authorizedInRc;

        if (needAuthorizeRc) {
          this.modalV2Service.showModal({ template: authorizeRcTemplate, className: 'wide' });
        }
      });
  }

  auth() {
    this.modalV2Service.hideFirst();

    this.appendScript();

    setTimeout(() => this.openAuthForm(), 1500);
  }

  appendScript() {
    const script = this.renderer.createElement('script');

    script.src = 'https://unpkg.com/@ringcentral/sdk@5.0.3/dist/ringcentral.js';
    script.type = 'text/javascript';
    script.async = true;

    this.renderer.appendChild(document.body, script);
  }

  openAuthForm() {
    // eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    if (window.RingCentral) {
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      const rcsdk = new window.RingCentral.SDK({
        clientId,
        clientSecret,
        server,
        redirectUri,
      });

      const platform = rcsdk.platform();
      const loginUrl = platform.loginUrl({ state: this.userId }) as string;

      window.open(loginUrl, '_blank');
    }
  }
}

const clientId = environment.RINGCENTRAL_CLIENT_ID;
const clientSecret = environment.RINGCENTRAL_CLIENT_SECRET;
const server = environment.RINGCENTRAL_SERVER;
const redirectUri = environment.RINGCENTRAL_REDIRECT_URI;
