import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://09b5515c229a48e4a1152535ffc96f96@o1146485.ingest.sentry.io/6215275',
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: ['localhost'],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [/ChunkLoadError: Loading chunk/g, /Invalid Date/g],
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
