import { Pipe, PipeTransform } from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { BookingNode } from '@app/generated/graphql';

@Pipe({
  name: 'isReadonlyCosts',
})
export class IsReadonlyCostsPipe implements PipeTransform {
  constructor(private cognitoAuthService: CognitoAuthService) {}

  transform(activeBooking: BookingNode) {
    const isOnlySalesRole = this.cognitoAuthService.isOnlySalesAgent;

    let isReadonlyForAllRoles;

    if (!this.cognitoAuthService.isTicketingAgent && activeBooking?.order?.ticketed) {
      isReadonlyForAllRoles = true;
    }

    if (this.cognitoAuthService.isAccountant) {
      isReadonlyForAllRoles = true;
    }

    if (activeBooking?.readOnly) {
      isReadonlyForAllRoles = true;
    }

    return Boolean(
      isReadonlyForAllRoles || (isOnlySalesRole && !activeBooking.order?.editableForSale)
    );
  }
}
